// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---plugins-gatsby-theme-mdx-deck-src-templates-deck-js": () => import("./../plugins/gatsby-theme-mdx-deck/src/templates/deck.js" /* webpackChunkName: "component---plugins-gatsby-theme-mdx-deck-src-templates-deck-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bio-js": () => import("./../src/pages/bio.js" /* webpackChunkName: "component---src-pages-bio-js" */),
  "component---src-pages-boilerplates-js": () => import("./../src/pages/boilerplates.js" /* webpackChunkName: "component---src-pages-boilerplates-js" */),
  "component---src-pages-cv-js": () => import("./../src/pages/cv.js" /* webpackChunkName: "component---src-pages-cv-js" */),
  "component---src-pages-disclaimer-js": () => import("./../src/pages/disclaimer.js" /* webpackChunkName: "component---src-pages-disclaimer-js" */),
  "component---src-pages-events-js": () => import("./../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pad-js": () => import("./../src/pages/pad.js" /* webpackChunkName: "component---src-pages-pad-js" */),
  "component---src-pages-photos-js": () => import("./../src/pages/photos.js" /* webpackChunkName: "component---src-pages-photos-js" */),
  "component---src-pages-presentations-js": () => import("./../src/pages/presentations.js" /* webpackChunkName: "component---src-pages-presentations-js" */),
  "component---src-pages-search-js": () => import("./../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-start-js": () => import("./../src/pages/start.js" /* webpackChunkName: "component---src-pages-start-js" */),
  "component---src-pages-stats-js": () => import("./../src/pages/stats.js" /* webpackChunkName: "component---src-pages-stats-js" */),
  "component---src-pages-uses-js": () => import("./../src/pages/uses.js" /* webpackChunkName: "component---src-pages-uses-js" */),
  "component---src-templates-article-tag-js": () => import("./../src/templates/ArticleTag.js" /* webpackChunkName: "component---src-templates-article-tag-js" */),
  "component---src-templates-articles-js": () => import("./../src/templates/Articles.js" /* webpackChunkName: "component---src-templates-articles-js" */),
  "component---src-templates-mdx-article-js": () => import("./../src/templates/MDXArticle.js" /* webpackChunkName: "component---src-templates-mdx-article-js" */),
  "component---src-templates-project-js": () => import("./../src/templates/Project.js" /* webpackChunkName: "component---src-templates-project-js" */),
  "component---src-templates-project-tag-js": () => import("./../src/templates/ProjectTag.js" /* webpackChunkName: "component---src-templates-project-tag-js" */),
  "component---src-templates-projects-js": () => import("./../src/templates/Projects.js" /* webpackChunkName: "component---src-templates-projects-js" */),
  "component---src-templates-qand-a-js": () => import("./../src/templates/QandA.js" /* webpackChunkName: "component---src-templates-qand-a-js" */)
}

